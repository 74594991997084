<template>
	<div>
		<ContentHeader title="Laporan Farmasi" subTitle="Laporan Penerimaan Stok" url="laporan-farmasi" />
		<div class="content-header">
			<div class="container-fluid">
				<div class="card">
          <div class="card-header">
            <h3 class="card-title">Data Laporan</h3>
          </div>
          <div class="card-body" style="display: block;">
            <div class="row" v-if="toogleCabang">
              <div class="col-lg-4">
                <label>Pilih Cabang</label>
                <multiselect 
                  v-model="cabang_select_value"
                  deselect-label="Can't remove this value"
                  track-by="nama"
                  label="nama"
                  placeholder=""
                  :options="cabang"
                  :loading="loading_cabang"
                  :allow-empty="false"
                  @update:model-value="getDataResult"
                >
                </multiselect>
              </div>
            </div>
            <div class="row align-items-center mt-2">
              <div class="col-lg-2">
                <span>Jumlah Baris</span>
                <select class="custom-select form-control" v-model="page" @change="getDataResult">
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                  <option value="30">30</option>
                  <option value="40">40</option>
                  <option value="45">45</option>
                  <option value="50">50</option>
                  <option value="1000000">Tampilkan Semua</option>
                </select>
              </div>
              <div class="col-lg-2">
      					<span>Periode Awal</span>
      					<input type="date" class="form-control" v-model="periode_awal">
      				</div>
      				<div class="col-lg-2">
      					<span>Periode Akhir</span>
      					<input type="date" class="form-control" v-model="periode_akhir">
      				</div>
      				<div class="col-lg-1 pt-4">
      					<button class="btn btn-primary" @click="getDataResult">Filter</button>
      				</div>
              <div class="col-lg-5"></div>
            </div>
            <table class="table table-bordered table-hover mt-4">
              <thead>
                <tr>
                  <th scope="col" class="text-sm">NO</th>
                  <th scope="col" class="text-sm">TANGGAL</th>
                  <th scope="col" class="text-sm">NO FAKTUR</th>
                  <th scope="col" class="text-sm">KODE PRODUK</th>
                  <th scope="col" class="text-sm">NAMA PRODUK</th>
                  <th scope="col" class="text-sm">SATUAN</th>
                  <th scope="col" class="text-sm">JUMLAH</th>
                  <th scope="col" class="text-sm">HARGA</th>
                  <th scope="col" class="text-sm">TOTAL HARGA</th>
                  <th scope="col" class="text-sm">DISKON</th>
                  <th scope="col" class="text-sm">TOTAL</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, index) in data_result" :key="index">
                  <td class="text-sm" scope="row">{{ index + 1 }}</td>
                  <td class="text-sm">{{ row.created_at.slice(0, 10) }}</td>
                  <td class="text-sm">{{ row.pembelian.nomor_faktur }}</td>
                  <td class="text-sm">{{ row.produk.kode }}</td>
                  <td class="text-sm">{{ row.produk.nama }}</td>
                  <td class="text-sm">{{ row.produk.satuan }}</td>
                  <td class="text-sm">{{ row.jumlah }}</td>
                  <td class="text-sm">{{ format_nominal(row.harga_beli) }}</td>
                  <td class="text-sm">{{ format_nominal(row.harga_beli * row.jumlah) }}</td>
                  <td class="text-sm">{{ convertDiskonToNominal(row.jumlah * row.harga_beli, row.diskon) }}</td>
                  <td class="text-sm">{{ format_nominal(row.total) }}</td>
                </tr>
              </tbody>
            </table>
            <p class="text-center mt-2" v-if="memuat_data">Memuat Data...</p>
            <p class="text-center mt-2" v-if="cek_data">Data Masih Kosong</p>
          </div>
          <!-- /.card-body -->
          <div class="card-footer">
            <nav aria-label="...">
              <ul class="pagination">
                <li class="page-item" :class="{ disabled: pagination.current_page == pagination.from}" @click="paginateData(pagination.prev_page_url)">
                  <a class="page-link" href="#" tabindex="-1" aria-disabled="true">Previous</a>
                </li>
                <li class="page-item" :class="{ active: pagination.current_page == row.label }" @click="paginateData(row.url)" v-for="(row, index) in links" :key="index"><a class="page-link" href="#">{{ row.label }}</a></li>
                <li class="page-item" :class="{ disabled: pagination.current_page == pagination.last_page}" @click="paginateData(pagination.next_page_url)">
                  <a class="page-link" href="#">Next</a>
                </li>
              </ul>
            </nav>
          </div>
          <!-- /.card-footer-->
        </div>
			</div>
		</div>
	</div>
</template>

<script>
import ContentHeader from '@/components/ContentHeader2'
import Multiselect from '@suadelabs/vue3-multiselect'

import { ref, computed, onMounted } from 'vue'
import store from '@/store'
import axios from 'axios'
import format_nominal from '@/format_nominal'

export default{
  components: {
    ContentHeader,
    Multiselect
  },
  setup(){
    const user = computed(() => store.getters['auth/user'])
    const data_result = ref([])
    const cek_data = ref(false)
    const page = ref(1000000)
    const links = ref([])
    const pagination = ref([])
    const memuat_data = ref(false)
    const kata_kunci = ref('')
    const periode_awal = ref('')
    const periode_akhir = ref('')
    const total = ref('')

    const getDataResult = async () => {
      data_result.value = []
      total.value = ''
      memuat_data.value = true
      cek_data.value = false
			let tanggal_awal = ''
      let tanggal_akhir = ''
      let cabang_id = ''

      if (cabang_select_value.value == '') {
        cabang_id = user.value.cabang_id
      } else {
        cabang_id = cabang_select_value.value.id
      }

      if (periode_awal.value == '' && periode_akhir.value == '') {
      	tanggal_awal = 'emptyParameter'
      	tanggal_akhir = 'emptyParameter'
      } else {
      	tanggal_awal = periode_awal.value
      	tanggal_akhir = periode_akhir.value
      }


      let { data } = await axios.get(`api/laporan/penerimaanStok/${cabang_id}/${page.value}/${tanggal_awal}/${tanggal_akhir}`)
      console.log(data)
      if (data != 'kosong') {
        memuat_data.value = false
        cek_data.value = false
        data_result.value = data.data.data
        total.value = data.total
        links.value = data.data.links
        links.value.splice(0, 1)
        links.value.splice(links.value.length-1, 1)
        pagination.value = data.data
      } else {
        cek_data.value = true
        memuat_data.value = false
        data_result.value = []
      }
    }

    const paginateData = async (url) => {
	    if (url != null) {
	      data_result.value = []
	      memuat_data.value = true

	      let link_url = new URL(url)
	      link_url = `${link_url.pathname}${link_url.search}`
	      
	      let { data } = await axios.get(`${link_url}`)

	      
	      if (data != 'kosong') {
	        memuat_data.value = false
	        data_result.value = data.data.data 
          total.value = data.total
	        links.value = data.data.links
	        links.value.splice(0, 1)
	        links.value.splice(links.value.length-1, 1)
	        pagination.value = data.data
	      }
	    }
		}

    const toogleCabang = ref(false)
    const cabang_select_value = ref('')
    const cabang = ref([])
    const loading_cabang = ref(true)
    const getCabang = async () => {
      let response = await axios.get(`api/cabang/getWhereId/${user.value.cabang_id}`)

      if (response.data.jenis == 'pusat') {
        toogleCabang.value = true
        let { data } = await axios.get(`api/cabang/getWhereCabang/${response.data.id}`)
        
        if (data != 'kosong') {
          cabang.value = data
          loading_cabang.value = false
        } else {
          cabang.value = []
          loading_cabang.value = false
        }
      }
    }

    const convertDiskonToNominal = (harga_awal, diskon) => {

      if (diskon > 0 && diskon <= 100) {
        return format_nominal((harga_awal * diskon) / 100)
      } else if (diskon == 0 || diskon == null) {
        return 0
      } else {
        return format_nominal(diskon)
      }
    }

    onMounted(() => {
      getCabang()
      getDataResult()
    })

    return {
      data_result, cek_data, memuat_data, links, paginateData, pagination, getDataResult, page, kata_kunci, format_nominal, periode_awal, periode_akhir, total, cabang_select_value, cabang, loading_cabang, toogleCabang, convertDiskonToNominal
    }
  }
}
</script>